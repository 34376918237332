
import * as React from 'react'

import './App.scss';


import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import BitStrmLogo from './content/BitStrmLogo'



import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';


import { ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";



import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';




import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

//import PaletteMode from '@mui/material/PaletteMode';

import { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import {
  type Container as pContainer,
  type ISourceOptions,
  MoveDirection,
  OutMode,
} from "@tsparticles/engine";
import { loadSlim } from "@tsparticles/slim";

import { color } from 'framer-motion';




// https://blog.logrocket.com/creating-responsive-sidebar-react-pro-sidebar-mui/



interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function HideOnScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}


/*



  onMouseEnter={() => setCollapsed(!collapsed)}
        onMouseUp={() => setCollapsed(collapsed)}
*/

/*
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1976d2',
    },
  },
});
const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
    },
  },
});
*/

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
    },
  },
});



/*
const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          primary: amber,
          divider: amber[200],
          text: {
            primary: grey[900],
            secondary: grey[800],
          },
        }
      : {
          // palette values for dark mode
          primary: deepOrange,
          divider: deepOrange[700],
          background: {
            default: deepOrange[900],
            paper: deepOrange[900],
          },
          text: {
            primary: '#fff',
            secondary: grey[500],
          },
        }),
  },
});
*/



function App() {
  
  const [collapsed, setCollapsed] = React.useState(true);
  const [sidebarHidden, setsidebarVisible] = React.useState(true);
  // const [theme, changeTheme] = React.useState(darkTheme);
  const [sectionColor, changeSectionColor] = React.useState('#0000ff');

  function collapseEnter()
  {
    if (collapsed) {setCollapsed(false)}
  }

  function collapseLeave()
  {
    if (!collapsed) {setCollapsed(true) }
  }

  const drawerAnchor = isMobile ? 'top' : 'right';

  const [init, setInit] = useState(false);

  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);
 
  const particlesLoaded = async (container?: pContainer): Promise<void> => {
    console.log(container);
  };

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary:{ main: prefersDarkMode ? '#000000' : '#ffffff'},
          secondary:{ main: prefersDarkMode ? '#000000' : '#ffffff'},
        },
      }),
    [prefersDarkMode],
  );

  const logo_source = prefersDarkMode ? './logo_dark.png' : './logo.png' 
  
  function ToggleColorMode ()  {
    const [mode, setMode] = React.useState<'light' | 'dark'>('light');
    const colorMode = React.useMemo(
      () => ({
        toggleColorMode: () => {
          setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        },
      }),
      [],
    );
  };

  


  const [openDrawer, setOpenDrawer] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpenDrawer(newOpen);
  };
  const DrawerIcons = [HomeOutlinedIcon, PeopleOutlinedIcon, ContactsOutlinedIcon, ReceiptOutlinedIcon, HelpOutlineOutlinedIcon, CalendarTodayOutlinedIcon ]

  const drawerThings = {"home":{"route": './', 'icon' : HomeOutlinedIcon }}

  const DrawerList = (
    <Box sx={{ width: isMobile ? window.innerWidth : 250, height: window.innerHeight }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );




  const options: ISourceOptions = useMemo(
    () => ({
      background: {
        color: {
          value: theme.palette.primary.main // "#0f0f0f",
        },
      },
      poisson: {
        enable: true
      },
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: false,
            mode: "push",
          },
          onHover: {
            enable: false,
            mode: "attract",
          },
        },
        modes: {
          push: {
            quantity: 4,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          attract: {
            distance: 200,
            duration: 0.4,
          },
        },
      },
      particles: {
        color: {
          value: sectionColor,
        },
        links: {
          color: sectionColor,
          distance: 120,
          enable: true,
          opacity: 0.7,
          width: 3,
        },
        rotate:{
          value: 0,
          random: true,
          direction: "random",
          animation: {
            enable: true,
            speed: 5,
            sync: false,
          }

        },
        move: {
          enable: true,
          random: false,
          speed: 1,
          straight: false,
          rotation: 2,
          pathRotation: true,
          attract: {
            enable: true,
            rotateX: 1,
          },
        },
        collisions: {
          enable: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 90,
        },
        opacity: {
          value: 1,
        },
        shape: {
          type: "square",
        },
        size: {
          value: { min: 3, max: 8 },
        },
      },
      detectRetina: true,
    }),
    [],
  );


// <BitStrmLogo />
  return (
    <div className="App" style={( { display: "flex", flexDirection: "row-reverse" })}>
      
        <ThemeProvider theme={theme}>
          <HideOnScroll>
            <AppBar className='AppBar' // position="fixed" 
              >
              <Toolbar className='AppToolBar' >
                {/*
                IconButton sx={{ ml: 1 }} style={{opacity: 0}}
                  // onClick={ToggleColorMode} // https://mui.com/material-ui/customization/dark-mode/
                  color="inherit">
                  {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
                */}
                <Container component="div" sx={{mr: 2, alignSelf: 'flex-end', alignContent: 'center' }}  style={{textAlign: 'center',
                                                                                      marginLeft: 'auto',
                                                                                      marginRight: 'auto',
                                                                                      marginTop: 'auto',
                                                                                      marginBottom: 'auto',  }} 
                >
                  <div className='pngLogoContainer'>
                    <a href="./">
                      <img className='png-Logo' rel="icon" src={logo_source}/>
                    </a>
                  </div>
                </Container>
                <IconButton onClick={toggleDrawer(true)} 
                  style={{textAlign: 'right'}} >
                  <MenuIcon/>
                </IconButton>
              
            </Toolbar>
          </AppBar>
          </HideOnScroll>

          <Drawer 
            style={{ opacity: 0.93}}
            open={openDrawer}
            anchor={drawerAnchor}
            onClose={toggleDrawer(false)}>
            {DrawerList}
          </Drawer>


        </ThemeProvider>
        
      

      <Sidebar className="SideBar" rtl={true} collapsed={collapsed} hidden={sidebarHidden}
        // style={{backgroundColor:'#000000', opacity: 1}}
        >
        <Menu onMouseOver={() => collapseEnter()} onMouseLeave={() => collapseLeave()}>
          <MenuItem icon={<HomeOutlinedIcon />}>Home</MenuItem>
          <MenuItem icon={<PeopleOutlinedIcon />}>Team</MenuItem>
          <MenuItem icon={<ContactsOutlinedIcon />}>Contacts</MenuItem>
          <MenuItem icon={<ReceiptOutlinedIcon />}>Profile</MenuItem>
          <MenuItem icon={<HelpOutlineOutlinedIcon />}>FAQ</MenuItem>
          <MenuItem icon={<CalendarTodayOutlinedIcon />}>Calendar</MenuItem>
        </Menu>
      </Sidebar>

      
      

      <Particles id="tsparticles"
        particlesLoaded={particlesLoaded}
        options={options}
        />

        <header className="App-header" >
        
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>

      <h2>
        Here i am
      </h2>
      
      
    </div>
  );
}

export default App;
// < img src={logo} className="App-logo" alt="logo" />